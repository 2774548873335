// https://kit.svelte.dev/docs/hooks#shared-hooks
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { dev } from '$app/environment';

if (!dev) {
    Sentry.init({
        dsn: 'https://cdba35afac9373f06545b96354a60594@o4505821044867073.ingest.sentry.io/4505821047947264',
        environment: process.env.NODE_ENV,
        integrations: [new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export async function handleError({ error, event }): Promise<HandleClientError> {
    const errorId = crypto.randomUUID();
    if (!dev) {
        Sentry.captureException(error, { extra: { event, errorId } });
    } else {
        console.warn('%c⚠️ handleError Event:', 'color: yellow; background: black; font-size: 16px', event);
        console.error('🔥', error);
    }

    return {
        message: error?.message || 'Whoops!',
        errorId,
    };
}
